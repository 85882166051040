import { MutationOptions, useMutation } from "react-query";
import { PotashReportingApi } from "../../../apis";
import { extractHttpErrorMessage } from "../../utils/http-error";
import { ExistingFileResponse, HTTPError } from "../../../types";

export const useDownloadExistingFile = (locationId: string, uploadedFileId: number) => {
  const mutationOptions: MutationOptions<ExistingFileResponse, HTTPError, string> = {
    onError: extractHttpErrorMessage,
    mutationFn: () =>
      PotashReportingApi.HrTemplates.downloadExistingFile(locationId, uploadedFileId)
  };
  const { mutateAsync, isLoading, isSuccess, isError, error } = useMutation(mutationOptions);

  return {
    downloadExistingFile: mutateAsync,
    loading: isLoading,
    isSuccess,
    isError,
    error: error?.message
  };
};
